export default {
  // The keys defined here will override those in the base locale file, for example:
  // 'theme_vendor': 'D-BAT'
  theme_vendor: 'Hybrid Jax',
  'customers.TermsDialog.accept':
    'By clicking ‘Accept’ you verify that you have read and accepted the policy and terms of {customerName, select, theme_vendor {} other {{customerName} and}} Upper Hand Inc.',
  'client_theme.ThemeSidebar.subHeader': 'Welcome',
  'client_theme.ThemeSidebar.mission':
    'Hybrid Jax provides a positive, professional training environment to enhance the development of athletes physical and mental performance in their sport. We specialize in training cheer athletes via our full-time programs, classes, and one-on-one sessions.',
  'client_theme.ThemeSidebar.learn_more': 'Learn More',
  'client_portal.PortalTabs.credit_passes': 'Credit Packs',
  'credit_passes.CreditPassListing.header': 'Credit Packs',
  'credit_passes.PurchaseDrawer.for_whom':
    'For whom would you like to purchase this pack?',
  'event_mgmt.shared._EventToolbar.browse_events': `Private & Classes`,
  'cart.index._Cart.browse_events': `Private & Classes`,
  'client_portal.PortalTabs.browse_events': `Private & Classes`,
  'client_portal.PortalTabs.beta_browse_events': `Private & Classes`,
};
